.section--ProgramSection {
  .title-wrapper {
    .title {
      background-color: var(--ac-program-bg-color);
      .c-richtext {
        color: var(--ac-program-content-color);
      }
    }
  }
  .c-item-list__item .c-item-list__hexa {
    background-color: var(--ac-program-bg-color);
    &:after {
      border-top-color: var(--ac-program-bg-color);
    }
    &:before {
      border-bottom-color: var(--ac-program-bg-color);
    }
    i {
      color: var(--ac-program-content-color);
    }
  }

  @media (max-width: 768px) {
    .c-richtext.content {
      &.left,
      &.right {
        p {
          text-align: center !important;
        }
      }
    }

    .program-wrapper {
      &.right {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

.c-program-table {
  width: 100%;

  & > div:not(:first-child) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 1.1em;

    &__time {
      width: 140px;
      flex-grow: 0;
      padding: 4px 8px;
      padding-left: 0;
    }

    &__description {
      flex: 1;
      padding: 4px 8px;
      padding-right: 0;
    }
  }
}

.column.is-12>.ProgramDay {
  .c-program-table__item__time {
    width: 220px;
  }


  @media (max-width: 768px) {
    .c-program-table__item__time {
      width: 140px;
    }
  }
}