.ShareModal {
  &--Backdrop {
    background-color: rgba(0,0,0, 0.6);
    color: black;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;

    @media (min-width: 768px) {
      justify-content: center;
      align-items: center;
    }

  }
  &--Card {
    position: relative;
    background-color: white;
    transition: transform 150ms ease-in-out;
    animation: 150ms ease-in-out 50ms 1 normal both ModalOpen;
    &--Content {
      max-height: 30vh;
      min-width: 30vw;
      overflow-x: auto;
      padding: 2em;
    }

    & > .fa-times {
      position: absolute;
      top: 0.1em;
      right: 0.2em;
      font-size: 1.8em;
      cursor: pointer;
    }
  }

}

@keyframes ModalOpen {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}