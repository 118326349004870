.LoginPage {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .container {
    height: 100%;
    padding: 1em;

    .button.is-primary {
      background-color: var(--ac-color-primary);
    }

    .box {
      .image-container {
        margin-bottom: 1em;
      }

      .image {
        margin: 0 auto;
        max-width: 80%;
        max-height: 120px;
      }
    }

    .columns {
      height: 100%;
    }
  }

  .sso-connections {
    .button {
      margin-bottom: 0.5em;
    }
  }

  .message {
    margin-top: 1em;
  }

  .connection-divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 0.5em 0;
    text-transform: uppercase;
    
    &:before, &:after {
      content: '';
      flex: 1;
      display: block;
      border-top: 1px solid rgba(128, 128, 128, 0.3);
    }
  }
}
