.workshops {
  &.is-left {
    display: flex;

    .workshop--filters {
      align-self: flex-start;
      margin-right: 1em;
      margin-top: 0.5em;

      .filters--mobile {
        display: none;
      }

      .menu {
        font-size: 0.85rem;

        .menu-label:first-child {
          margin-top: 1em;
        }

        .menu-list a.is-active {
          font-weight: bold;
          color: inherit;
          background: transparent;
        }

      }
    }
  }

  .workshop {
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    position: relative;

    &--filters {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding: 0.8em;

      .label {
        flex: 1;
        margin: 0px;

        &.clear {
          color: #1075ff;
          cursor: pointer !important;
          font-weight: normal;
        }
      }

      &.vertical {
        display: block;
        min-width: 220px;
        max-width: 300px;
      }
    }

    &--content {
      display: flex;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;
        margin-bottom: 10px;
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }

      .main.title {
        flex: 1;
        font-weight: bold;
        font-size: 18px;
      }

      .category {
        color: white;
        padding: 2px 16px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 20px;
        margin-left: 10px;
      }

      .date, .subCategory, .location {
        margin-top: 4px;
        margin-bottom: 10px;
      }

      .description {
        text-align: justify;
      }

      .speakers {
        margin-top: 10px;

        &.bubble {
          display: flex;
          flex-wrap: wrap;

          .speaker {
            margin-right: -1em;
          }

          figure.image.left {

            img {
              border: 4px solid #fff;
            }
          }
        }

        &.list {
          .speaker {
            margin-bottom: 10px;
          }
        }
      }
    }

    .footer {
      background-color: white;
      border-top-width: 2px;
      border-top-style: solid;
      padding: 0px;
      display: flex;
      justify-content: space-between;

      .tag {
        margin: 10px 0px;
        font-size: 14px;

        &.userCount {
          &>i {
            margin-right: 10px;
          }
        }

        &.register {
          cursor: pointer;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}

.timeline {
  margin: 1em auto;
  position: relative;

  &:before {
    background-color: black;
    content: "";
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 1em;
    width: 2px;
    height: 100%;
  }

  &-event {
    position: relative;

    &:hover {
      .timeline-event-icon {
        transform: rotate(-45deg);
      }
    }

    &-content {
      padding: 2em 1em 1em 1.4em;
      position: relative;
      top: -1.875em;
      left: 1em;
    }

    &-icon {
      transition: transform 0.2s ease-in;
      transform: rotate(45deg);
      background-color: black;
      outline: 10px solid #fafafa;
      display: block;
      margin: 0.5em 0.5em 0.5em -0.5em;
      position: absolute;
      top: 0;
      left: 1em;
      width: 1em;
      height: 1em;
    }

    &-header {
      color: white;
      font-size: 0.75em;
      background-color: black;
      display: inline-block;
      margin-bottom: 1.2em;
      padding: 0.4em 1em 0.4em 1em;
    }
  }
}

@media screen and (max-width: 480px) {
  .workshops {
    display: block !important;

    .workshop {
      &--content {
        display: block;
      }

      .footer {
        width: auto !important;
        text-align: center;
        display: block !important;

      }

      &--filters {
        display: block;

        .label {
          margin-bottom: 10px;
        }

        &.vertical {
          width: 100%;
          max-width: unset;

          .label {
            margin: 0px;
          }
        }

        .dropdown.is-hoverable {
          margin-bottom: 10px;
          width: 100%;

          .dropdown-trigger {
            width: 100%;

            .button {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .workshop--filters {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .workshop--filters {
    display: block !important;

    &.menu {
      padding: 0px;
    }

    .filters--desktop {
      display: none !important;
    }

    .filters--mobile {
      display: block !important;

      &__button {
        border: none;
        width: 100%;
      }
    }

    // .menu-filter--simple {
    //   display: none;
    // }
  }
}