.workshops {
  &.Modern {
    .workshop {
      padding: 1rem;
      background-color: #ffffff;
      box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      border-radius: 1em;
      margin: 14px 0px;
      &--content {
        display: block;

        .date {
          display: block;
          color: rgba(0, 0, 0, 0.7);
          font-size: 16px;
          font-weight: bold;
          margin: 0px;
        }

        .category {
          background: linear-gradient(to right, rgba(79, 172, 254, 1), rgba(0, 242, 254, 0.7));
        }

        main.title {
          font-size: 1.2em;
          margin: 10px 0px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &--image {
        min-width: 22%;
        max-width: 22%;
        height: 11em;
        transform: translateX(-3rem);
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 0.8rem;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(to right, rgba(79, 172, 254, 0.6), rgba(0, 242, 254, 0.6));
          box-shadow: 0.5rem 0.5rem 3rem 1px rgba(0, 0, 0, 0.05);
          border-radius: 0.8rem;
        }
      }
      .footer {
        .tag {
          &.register {
            cursor: pointer;
            border: none;
            display: inline-block;
            padding: 0.7em 1em;
            text-transform: uppercase;
            font-size: 0.8em;
            font-weight: bold;
            color: #fff;
            height: auto !important;
            background-image: linear-gradient(to left, #4facfe 0%, #00f2fe 100%);
            border-radius: 0.8rem;
            text-decoration: none;
            transition: all 1s ease-in-out;
            &:hover {
              background-image: linear-gradient(to left, #00f2fe 0%, #4facfe 100%);
            }
          }
        }
      }
    }
  }
}

.speakers {
  margin-bottom: 1em;
  &.list {
    .speaker {
      margin-bottom: 10px;
    }
  }

  &.bubble {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .workshops {
    &.Modern {
      .workshop {
        padding: 1.7rem;
        flex-direction: column;
        &--image {
          min-width: 100%;
          max-width: 100%;
          transform: translate(0, -0.5em);
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .workshops {
    &.Modern {
      .workshop {
        &--content {
          .header {
            display: block;
          }
        }
      }
    }
  }
}
