.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.columns {
  &.is-reversed {
    flex-direction: row-reverse;
  }
}
