.image-section {
    width: 100%;
    margin: 0 auto;
    
    &--xs { max-width: 400px; }
    &--sm { max-width: 600px; }
    &--md { max-width: 800px; }
    &--lg { max-width: 1000px; }
    &--xl { max-width: 1200px; }
    
    @media screen and (max-width: 768px) {
      &--lg,
      &--xl {
        max-width: 100%;
      }
    }
    
    @media screen and (max-width: 576px) {
      &--md {
        max-width: 100%;
      }
    }
    
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }