
:root {
    color-scheme: light;

    --cc-bg: #f9faff;
    --cc-primary-color: var(--ac-color-primary);
    --cc-secondary-color: #111111;

    --cc-btn-primary-bg: var(--ac-color-primary);
    --cc-btn-primary-color: var(--cc-bg);
    --cc-btn-primary-hover-bg: var(--ac-color-primary); // TODO: hover ?
    --cc-btn-primary-hover-color: #fff;

    --cc-btn-secondary-bg: #dfe7f9;
    --cc-btn-secondary-color: var(--cc-secondary-color);
    --cc-btn-secondary-hover-bg: #c6d1ea;
    --cc-btn-secondary-hover-color: #000;

    --cc-cookie-category-block-bg: #ebeff9;
    --cc-cookie-category-block-border: #ebeff9;
    --cc-cookie-category-block-hover-bg: #dbe5f9;
    --cc-cookie-category-block-hover-border: #dbe5f9;
    --cc-cookie-category-expanded-block-hover-bg: #ebeff9;
    --cc-cookie-category-expanded-block-bg: #ebeff9;

    --cc-overlay-bg: rgba(219, 232, 255, 0.85)!important;

    --cc-toggle-readonly-bg: #cbd8f1;
    --cc-toggle-on-knob-bg: var(--cc-bg);
    --cc-toggle-off-bg: #8fa8d6;
    --cc-toggle-readonly-knob-bg: var(--cc-bg);

    --cc-separator-border-color: #f1f3f5;

    --cc-footer-border-color: #f1f3f5;
    --cc-footer-bg: var(--cc-bg);

    --cc-modal-border-radius: var(--cc-btn-border-radius);
    --cc-pm-toggle-border-radius: var(--cc-btn-border-radius);
}

.cm {
    .cm__btn:hover {
        opacity: 0.8 !important;
    }
}

#cc-main {
    color: var(--cc-secondary-color);

    .pm__section .pm__section-desc a {
        color: var(--cc-link-color);
    }
}