.card{
&.card {
    &--horizontal{
        .card-content {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .media {
            align-items: center;
        }
        height: 100%;

    }
}
}