.Accordion {
    &__trigger {
        padding: 0.8em;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-top: 1px solid #ccc;

        .info {
            flex: 1;
            margin: 0px;

            .title {
                font-size: 1.1em;
            }

            .time {
                font-size: 0.9em;
                font-weight: bold;
                margin-bottom: 6px;
            }
        }

        .c-icon {
            font-size: 1.2em;
            margin-bottom: 0px;
            line-height: initial;
            height: unset;
            width: unset;
        }
    }

    &__details {
        padding: 0.8em;
        padding-top: 0px;

        .speaker {
            .title {
                font-size: 1em;
            }

            .subtitle {
                font-family: 0.8em;
                color: #777;
            }
        }
    }

    &__Group {
        &.column {
            padding: 2em;
            text-align: center;
            cursor: pointer;

            .title {
                color: white !important;
            }
        }
    }
}