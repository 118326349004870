@mixin hexGrid($gridSize) {
  $width: percentage(calc(1 / $gridSize));

  padding-bottom: percentage(calc(0.22 / $gridSize));
  .hex {
    width: $width;
    &:nth-child(#{2 * $gridSize - 1}n + #{$gridSize + 1}) {
      margin-left: calc($width / 2);
    }
  }
}

@mixin responsiveGrid($gridSize) {
  $width: percentage(calc(1 / $gridSize));
  .hexGrid {
    &.is-3,
    &.is-4,
    &.is-5,
    &.is-6 {
      padding-bottom: percentage(calc(0.22 / $gridSize));
      .hex {
        width: $width !important;
        margin-left: 0px !important;
        &:nth-child(#{2 * $gridSize - 1}n + #{$gridSize + 1}) {
          margin-left: calc($width / 2) !important;
        }
      }
    }
  }
}

.hexGrid {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  font-size: 15px;
  list-style-type: none;

  &.is-centered {
    justify-content: unset;
  }

  &.is-gapless {
    margin: auto;
  }

  &.is-2 {
    @include hexGrid(2);
  }
  &.is-3 {
    @include hexGrid(3);
  }
  &.is-4 {
    @include hexGrid(4);
  }
  &.is-5 {
    @include hexGrid(5);
  }
  &.is-6 {
    @include hexGrid(6);
  }
}
.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  &::after {
    content: "";
    display: block;
    padding-bottom: 86.602%;
  }

  .hexIn {
    position: absolute;
    width: 96%;
    padding-bottom: 110.851%; /* = width / sin(60) */
    margin: 0 2%;
    overflow: hidden;
    visibility: hidden;
    outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
    transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);

    .hexLink {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #fff;
      overflow: hidden;
      transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);

      &:hover p,
      &:focus p {
        transform: translate3d(0, 0, 0) !important;
      }

      .image {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        left: -100%;
        right: -100%;
        width: auto;
        height: 100%;
        margin: 0 auto;
        transform: rotate3d(0, 0, 0, 0deg);
        &.image--color-overlay::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      p {
        color: white;
        width: 100%;
        padding: 5%;
        box-sizing: border-box;
        font-weight: 300;
        transition: transform 0.2s ease-out, opacity 0.3s ease-out;

        &.title {
          bottom: 50%;
          padding-top: 50%;
          z-index: 1;
          margin-bottom: 0px;
          transform: translate3d(0, -100%, 0);

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 45%;
            width: 10%;
            text-align: center;
            border-bottom: 1px solid #fff;
          }
        }

        &.subtitle {
          top: 50%;
          padding-bottom: 50%;
          margin-top: 0px !important;
          transform: translate3d(0, 100%, 0);
        }
      }
    }
  }
  .hexIn * {
    position: absolute;
    visibility: visible;
    outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  }
}
.hex.column {
  flex-basis: unset !important;
  flex-grow: unset !important;
}

@media (max-width: 1200px) and (min-width: 901px) {
  @include responsiveGrid((4));
}

@media (max-width: 900px) and (min-width: 601px) {
  @include responsiveGrid((3));
}

@media (max-width: 600px) {
  @include responsiveGrid(2);
}
