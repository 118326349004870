.section--DataItemSection {
  .column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    transition: all 300ms;
    cursor: pointer;

    .card {
      flex: 1;
    }
  }
  &-animated {
    &--scale-up {
      .column:hover {
        transform: scale(1.05);
      }
    }
    &--scale-down {
      .column:hover {
        transform: scale(0.95);
      }
    }
    &--move-up {
      .column:hover {
        transform: translateY(-5px);
      }
    }
    &--move-down {
      .column:hover {
        transform: translateY(5px);
      }
    }
  }
}
