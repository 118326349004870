.FilesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__FileLink {
    flex: 0 1 230px;
    margin: 0.5rem;
    box-sizing: border-box;
    transition: transform 80ms ease-in-out;

    &:hover {
        transform: translate(1px, 1px);
    }
  }
}