.collapsible-cards {
  text-align: center;

  &.showing {
    .workshop {
      cursor: pointer;
      opacity: 0.2;
      transform: scale(0.88);
    }
  }

  .columns>.column>.card {
    height: 100%;
    width: 100%;
  }

  .workshop {
    background: white;
    display: inline-block;
    margin: 8px;
    position: relative;
    text-align: left;
    transition: all 0.3s 0s ease-in;
    z-index: 1;

    .card-content {
      background-color: white;
      padding: 1em;
    }

    .workshop--content {
      display: block !important;
      background: transparent;
      position: relative;
      z-index: 0;

      .header {
        .date {
          font-weight: bold;
          margin-bottom: 0px;
        }
      }

      .main.title {
        margin-bottom: 4px;
        margin-right: 10px;
      }

      .chevron {
        background: black;
        border-radius: 32px;
        height: 32px;
        width: 32px;
        color: white;
        display: inline-block;
        position: relative;
        cursor: pointer;
        transition: all 0.1s 0s ease-out;

        span {
          background: white;
          display: block;
          height: 2px;
          position: absolute;
          top: 16px;
          width: 12px;
        }

        span.left {
          right: 14px;
          transform: rotate(45deg);
        }

        span.right {
          left: 14px;
          transform: rotate(-45deg);
        }
      }
    }

    .description {
      position: relative;
      font-size: 14px;
      margin-bottom: 10px;
      text-align: justify;
      background-color: white;
    }

    .card-flap {
      background: darken(transparent, 15);
      position: absolute;
      width: 100%;
      transform-origin: top;
      transform: rotateX(-90deg);
      left: 0;
      right: 0;
      padding: 1em;

      .speakers.list {
        .speaker {
          margin-bottom: 10px;
        }
      }
    }
  }
}

div.card.show {
  opacity: 1 !important;
  transform: scale(1) !important;

  div.workshop--content {
    display: block !important;

    .chevron {
      background: salmon !important;

      span {
        top: 15px;
      }

      span.left {
        right: 10px;
      }

      span.right {
        left: 10px;
      }
    }
  }

  div.card-flap {
    background: white;
    transform: rotateX(0deg);
    box-shadow: 0 4rem 4rem rgba(22, 28, 45, 0.2);
  }
}

@media screen and (max-width: 480px) {
  .collapsible-cards {
    .card.workshop {
      margin-left: 0px;
      margin-right: 0px ;
    }
    .columns {
      margin-left: 0px;
      margin-right: 0px;
      .column {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}