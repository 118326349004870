.section {
  &.section {
    &--TextButtonSection {
      .container {
        .button-container {
          text-align: center;
        }

        div~ {
          .button-container {
            .button {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
}