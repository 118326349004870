.section--WorkshopDetailsSection>.workshop {
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  border-radius: 1em;

  &--image {
    min-width: 50%;
    max-width: 50%;
    height: 11em;
    margin-right: 3rem;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 0.8rem;
    }
  }

  &--content {
    display: block;

    .date {
      display: block;
      color: rgba(0, 0, 0, 0.7);
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
    }

    .category {
      color: white;
      padding: 2px 16px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      border-radius: 20px;
      margin-left: 10px;
      background: rgba(79, 172, 254, 1);
    }

    .main.title {
      flex: 1;
      font-weight: bold;
      font-size: 18px;
    }
    .description {
      text-align: justify;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      margin-bottom: 10px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
    .tag {
      background-color: #e8e8e8;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 700;
      margin: 3px;
      text-transform: none;
    }
  }

  &--replay {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-start;
    h3 {
      color: #2185d0;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.28571429em;
      margin-bottom: 15px;
    }
    .video-container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .section--WorkshopDetailsSection>.workshop {
    padding: 1.7rem;
    flex-direction: column;
    &--image {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 480px) {
  .section--WorkshopDetailsSection>.workshop {
    padding: 1rem;
    &--content {
      .header {
        display: block;
      }
    }
  }
}
