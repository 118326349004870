.ShareButton {
  &--Trigger {
    margin: 5px;
    cursor: pointer;
  }

  &--Drawer {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 100%;

    &--Content {
      margin: 0 auto;

      display: flex;
      flex-direction: column;

      & > * {
        margin: auto 0; /* space evenly fallback */
      }
    }
  }

  &--ButtonContent {
    display: flex;
    align-items: center;
    font-weight: bold;
    & span {
      display: inline-block;
      margin-left: 1em;
    }
  }
}