.workshops {
  &.List {
    .workshop {
      &--content {
        .header {
          margin-bottom: 0px;
          padding-bottom: 0px;
          border-bottom: none;
        }
        .lang {
          margin-right: 10px;
        }

        .main.title {
          margin-bottom: 0px!important;
        }

        .left-section {
          max-width: 200px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .category {
            margin-bottom: 10px;
          }

          .tag {
            font-size: 14px;
            &.userCount {
              & > i {
                margin-right: 10px;
              }
            }

            &.register {
              cursor: pointer;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .workshops {
    &.List {
      .workshop--content {
        .lang {
          position: absolute;
          right: 0px;
          top: 2px;
        }
        .left-section {
          padding-top: 10px;
          border-top-width: 3px;
          border-top-style: solid;
          width: 100%;
          max-width: 100%;
          align-items: center;
        }
      }
    }
  }
}
