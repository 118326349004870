.c-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 50px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  color: #333;
  &--diamond {
    width: 120px;
    height: 120px;
    line-height: 120px;
    &:before {
      content: "";
      pointer-events: none;
      width: 120px;
      height: 120px;
      line-height: 120px;
      border: 1px solid #e0e0e0;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.25s ease 0s;
    }
  }
  &--bubble {
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    border: 1px solid #333;
    color: #333;
    margin: 0 auto 15px;
  }
  &--circle {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 48px;
    border-radius: 50%;
    background: #333;
    color: white;
    margin: 0 auto 15px;
  }
  &--square {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 48px;
    border-radius: 8px;
    background: #333;
    color: white;
    margin: 0 auto 15px;
  }
  &--hexa {
    height: 50px;
    line-height: 50px;
    font-size: 40px;
    background: #333;
    color: white;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
    &:before {
      content: "";
      pointer-events: none;
      border-bottom: 20px solid #333;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      top: -20px;
    }
    &:after {
      content: "";
      pointer-events: none;
      border-top: 20px solid #333;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      bottom: -20px;
    }
  }
}

.c-icon-hover:hover .c-icon--diamond:before,
.c-icon--diamond:hover:before {
  width: 93px;
  height: 93px;
  top: 13px;
  left: 13px;
  border: 3px solid #333;
  transform: rotate(45deg);
}

// .c-icon-hover:hover .c-icon--bubble:before,
// .c-icon--bubble:hover:before {
//   box-shadow: 3px 3px 0 rgba(38, 183, 138, 0.89);
// }